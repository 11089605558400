import React, {useCallback, useContext, useState} from 'react';
import {Button, Form, Input, message, Space, Table} from 'antd';
import styled from 'styled-components';
import queryString from 'query-string';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {navigate} from 'gatsby';
import JStorageForm from './JStorageForm';
import URL_PATH from '../../UrlPath';
import {format} from '../../utils/date';
import {SearchOutlined} from '@ant-design/icons';
import GenericInput, {INPUT_TYPES} from '../../components/GenericAntdInput';
import ModalContent from '../../components/ModalContent';
import {PROVIDER, PROVIDER_DISPLAY} from '../../domain/Constants';

const CreateUserFormModal = (props) => {
  const {updateList} = props;
  const app = useContext(AppContext.Context);
  const initialState = {
    username: '',
    password: '00000000',
  };
  const [form] = Form.useForm();

  const getInputProps = useCallback(
    (field) => {
      return {
        username: {
          label: '會員電話號碼',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '會員電話號碼必須填寫'},
            {
              pattern: '^([0|+[0-9]{1,5})?([7-9][0-9]{8})$',
              message: '會員電話號碼格式錯誤',
            },
          ],
          onChange: async (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請填寫會員電話號碼',
        },
        password: {
          label: '會員預設密碼',
          name: field,
          value: form.getFieldValue(field),
          rules: [{required: true, message: '會員預設密碼必須填寫'}],
          onChange: async (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.password,
          placeholder: '請填寫會員預設密碼',
        },
      }[field];
    },
    [form],
  );

  const commonItemConfig = {
    colon: false,
    labelAlign: 'left',
    style: {display: 'flex', flexDirection: 'column'},
  };

  const onSubmit = async (data) => {
    try {
      AppActions.setLoading(true);
      await app.actions.staffCreateUser(data);
      AppActions.setModal(null);
      await updateList();
    } catch (e) {
      console.log(e);
      if (e.error === 'user_exist_error') {
        message.error('此會員已存在');
      } else {
        message.error('系統錯誤，聯絡開發人員');
      }
    } finally {
      AppActions.setLoading(false);
    }
  };

  return (
    <ModalContent title="建立會員表單">
      <Form form={form} initialValues={initialState} onFinish={onSubmit}>
        <GenericInput
          config={commonItemConfig}
          {...getInputProps('username')}
        />
        <GenericInput
          config={commonItemConfig}
          {...getInputProps('password')}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <small style={{color: '#f00', paddingRight: 10}}>
            * 請注意，如未更動預設密碼，預設密碼為 8 個 0 *
          </small>
          <Button htmlType="submit" type="primary">
            建立會員
          </Button>
        </div>
      </Form>
    </ModalContent>
  );
};

const PassPhoneValidationFormModal = (props) => {
  const {user, refreshDetail} = props;
  const app = useContext(AppContext.Context);
  const initialState = {
    phone: '',
  };
  const [form] = Form.useForm();

  const getInputProps = useCallback(
    (field) => {
      return {
        phone: {
          label: '會員電話號碼',
          name: field,
          value: form.getFieldValue(field),
          rules: [
            {required: true, message: '會員電話號碼必須填寫'},
            {
              pattern: '^([0|+[0-9]{1,5})?([7-9][0-9]{8})$',
              message: '會員電話號碼格式錯誤',
            },
          ],
          onChange: async (e) => {
            form.setFieldsValue({[field]: e.target.value});
          },
          type: INPUT_TYPES.text,
          placeholder: '請填寫會員電話號碼',
        },
      }[field];
    },
    [form],
  );

  const commonItemConfig = {
    colon: false,
    labelAlign: 'left',
    style: {display: 'flex', flexDirection: 'column'},
  };

  const onSubmit = async (data) => {
    try {
      AppActions.setLoading(true);
      const payload = {
        user_id: user.owner,
        phone: data.phone,
      };
      const resp = await app.actions.staffVerifyPhone(payload);
      await refreshDetail();
      AppActions.setModal(null);
    } catch (e) {
      console.log('debug', e);
      message.error('系統錯誤，聯絡開發人員');
    } finally {
      AppActions.setLoading(false);
    }
  };

  return (
    <ModalContent title="強制通過手機驗證表單">
      <Form form={form} initialValues={initialState} onFinish={onSubmit}>
        <GenericInput config={commonItemConfig} {...getInputProps('phone')} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
          <Button htmlType="submit" type="primary">
            強制通過手機驗證
          </Button>
        </div>
      </Form>
    </ModalContent>
  );
};

const PATH = URL_PATH.dashboardMembers;
const PAGE_SIZE = 10;

const schema = {
  title: '會員資料',
  type: 'object',
  required: ['owner'],
  properties: {
    owner: {type: 'string', title: 'id'},
    name: {type: 'string', title: '會員姓名'},
    phone: {type: 'string', title: '手機'},
    birthdate: {type: 'string', title: '生日'},
    email: {type: 'string', title: 'email'},
    tel: {type: 'string', title: '市話'},
    tel_ext: {type: 'string', title: '市話（分機）'},
    zip: {type: 'string', title: '郵遞區號'},
    city: {type: 'string', title: '地址（縣市）'},
    district: {type: 'string', title: '地址（區）'},
    address: {type: 'string', title: '地址'},
  },
};

const uiSchema = {
  owner: {
    'ui:readonly': true,
  },
  name: {
    'ui:readonly': true,
  },
  phone: {
    'ui:readonly': true,
  },
  birthdate: {
    'ui:readonly': true,
  },
  email: {
    'ui:readonly': true,
  },
  tel: {
    'ui:readonly': true,
  },
  tel_ext: {
    'ui:readonly': true,
  },
  zip: {
    'ui:readonly': true,
  },
  city: {
    'ui:readonly': true,
  },
  district: {
    'ui:readonly': true,
  },
  address: {
    'ui:readonly': true,
  },
};

function MembersPage(props) {
  const app = React.useContext(AppContext.Context);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    total: 0,
    pageSize: PAGE_SIZE,
  });
  const [tableStateSnapshot, setTableStateSnapshot] = React.useState({});
  const [fetchConfigSnapshot, setFetchConfigSnapshot] = React.useState({
    paging: {
      offset: 0,
      limit: PAGE_SIZE,
    },
    query: {},
    sorting: ['-created'],
  });
  const params = queryString.parse(props.location.search);
  const selectedId = params.id;
  const [secretActionsVisible, setSecretActionsVisible] = useState(0);
  const timesOfSecretActionShown = 3;

  const tableColumns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '登入方式',
      dataIndex: 'provider',
      key: 'provider',
      render: (_, record, idx) => {
        return PROVIDER_DISPLAY[record.provider].label;
      },
    },
    {
      title: '電話',
      dataIndex: 'phone',
      key: 'phone',
      filteredValue: tableStateSnapshot.filters?.phone || null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{padding: 8}}>
          <Input
            placeholder={`0912345678`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button onClick={clearFilters} size="small" style={{width: 90}}>
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                console.log(selectedKeys, confirm, 'phone');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}>
              搜尋
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '登入方式',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: '註冊時間',
      dataIndex: 'created',
      key: 'created',
      render: (_, record, idx) => {
        return format(record.created, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'created' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.created - b.created,
    },
    {
      title: '更新時間',
      dataIndex: 'updated',
      key: 'updated',
      render: (_, record, idx) => {
        return format(record.updated, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'updated' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.updated - b.updated,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return (
          <Button
            onClick={async () => {
              setSecretActionsVisible(0);
              await navigate(`${PATH}?id=${record.owner}`);
            }}>
            查看
          </Button>
        );
      },
    },
  ];

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      if (selectedId) {
        try {
          setSelectedRecord(await app.actions.staffGetMemberById(selectedId));
        } catch (ex) {
          console.warn(ex);
        }
      } else {
        setSelectedRecord(null);
        await _updateList();
      }

      AppActions.setLoading(false);
    }

    if (app.staffUser) {
      fetchData();
    }
  }, [app.actions, selectedId, app.staffUser]);

  const _updateList = React.useCallback(
    async (configs = null) => {
      setIsLoading(true);
      try {
        let _configs = configs || fetchConfigSnapshot;
        let _resp = await app.actions.staffGetMembers(_configs);
        let _list = _resp.results;
        setPagination({
          total: _resp.total,
          pageSize: PAGE_SIZE,
          current: _configs.paging.offset / PAGE_SIZE + 1,
        });
        setRecords(_list);
      } catch (ex) {
        console.warn(ex);
      }
      setIsLoading(false);
    },
    [app.actions, fetchConfigSnapshot],
  );

  const SEARCH_FIELDS = ['phone'];

  const _onTableChange = React.useCallback(
    async (
      pagination, // {current: 1, pageSize: 3}
      filters,
      sorter,
      // currentDataSource: [], action: paginate | sort | filter
      extra,
    ) => {
      let _configs = {
        paging: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: {},
        sorting: ['-created'],
      };
      let _queries = [];
      for (let key of Object.keys(filters)) {
        if (filters[key] && filters[key].length > 0) {
          if (SEARCH_FIELDS.indexOf(key) !== -1) {
            let _resultTypeQuery = {
              $or: filters[key].map((v) => ({[key]: {$regex: `.*${v}.*`}})),
            };
            _queries.push(_resultTypeQuery);
          } else {
            let _resultTypeQuery = {
              $or: filters[key].map((v) => ({[key]: v})),
            };
            _queries.push(_resultTypeQuery);
          }
        }
      }

      _configs.query = _queries.length > 0 ? {$and: _queries} : {};
      if (extra.action === 'paginate') {
        console.log('is pagination!!');
        _configs.paging.offset = (pagination.current - 1) * pagination.pageSize;
      }
      if (sorter && sorter.field && sorter.order) {
        _configs.sorting = [
          `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`,
        ];
      }

      AppActions.setLoading(true);
      await _updateList(_configs);
      setFetchConfigSnapshot(_configs);
      setTableStateSnapshot({filters, sorter});
      AppActions.setLoading(false);
    },
    [_updateList],
  );

  if (!selectedRecord) {
    return (
      <Wrapper>
        <h2>會員列表</h2>
        <div
          style={{
            opacity: 0,
            width: 80,
            height: 50,
            cursor: 'not-allowed',
            top: 20,
            right: 20,
            position: 'absolute',
          }}
          onClick={() => {
            setSecretActionsVisible((prev) => prev + 1);
          }}
        />

        {secretActionsVisible >= timesOfSecretActionShown && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 16,
            }}>
            <Button
              type="primary"
              onClick={() => {
                AppActions.setModal(
                  <CreateUserFormModal updateList={_updateList} />,
                );
              }}>
              建立會員
            </Button>
          </div>
        )}

        <Table
          locale={{
            triggerDesc: '排序遞減',
            triggerAsc: '排序遞增',
            cancelSort: '取消排序',
          }}
          loading={isLoading}
          dataSource={records}
          columns={tableColumns}
          rowKey={(record) => record.owner}
          onChange={_onTableChange}
          pagination={{...pagination, showSizeChanger: false}}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div>
            <Button
              style={{marginRight: 10}}
              onClick={async () => {
                setSecretActionsVisible(0);
                setSelectedRecord(null);
                await navigate(PATH);
              }}>
              返回
            </Button>
            {secretActionsVisible >= timesOfSecretActionShown &&
              selectedRecord.provider === PROVIDER.default && (
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  type="danger"
                  onClick={async () => {
                    try {
                      AppActions.setLoading(true);
                      await app.actions.staffResetUserPassword(
                        selectedRecord.owner,
                      );
                      message.success('重新設定成功');
                      setSecretActionsVisible(0);
                    } catch (e) {
                      message.error('重新設定失敗');
                    } finally {
                      AppActions.setLoading(false);
                    }
                  }}>
                  重設密碼
                </Button>
              )}

            {secretActionsVisible >= timesOfSecretActionShown &&
              selectedRecord.provider !== PROVIDER.default && (
                <Button
                  style={{
                    marginRight: 10,
                  }}
                  type="danger"
                  onClick={() => {
                    AppActions.setModal(
                      <PassPhoneValidationFormModal
                        user={selectedRecord}
                        refreshDetail={async () =>
                          setSelectedRecord(
                            await app.actions.staffGetMemberById(selectedId),
                          )
                        }
                      />,
                    );
                  }}>
                  強制通過手機驗證
                </Button>
              )}

            {secretActionsVisible >= timesOfSecretActionShown && (
              <Button
                style={{
                  marginRight: 10,
                }}
                type="danger"
                onClick={async () => {
                  try {
                    AppActions.setLoading(true);
                    await app.actions.staffDeleteUser(selectedRecord.owner);
                    message.success('刪除成功');
                    setSecretActionsVisible(0);
                    setSelectedRecord(null);
                    await navigate(PATH);
                  } catch (e) {
                    message.error('刪除失敗');
                  } finally {
                    AppActions.setLoading(false);
                  }
                }}>
                刪除此會員
              </Button>
            )}
          </div>

          <div
            style={{
              opacity: 0,
              width: 80,
              height: 50,
              cursor: 'not-allowed',
            }}
            onClick={() => {
              setSecretActionsVisible((prev) => prev + 1);
            }}
          />
        </div>

        <JStorageForm
          schema={schema}
          uiSchema={uiSchema}
          instance={selectedRecord}
          onSubmit={null}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px;
  position: relative;

  & > h2 {
    margin-bottom: 16px;
  }
`;

export default MembersPage;
